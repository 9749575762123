import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'

export function useBlockNumber() {
  const { library } = useWeb3React<ethers.providers.Web3Provider>()
  const [blockNumber, setBlockNumber] = useState(0)

  useEffect(() => {
    function update(bn: number) {
      setBlockNumber(bn)
    }

    if (library) {
      library.on('block', update)

      return () => {
        library.off('block', update)
      }
    }
  })

  return blockNumber
}
